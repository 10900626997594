const GERMAN_MESSAGES = {
    logout: 'Abmelden',

    search_placeholder: 'Geben Sie einen Suchbegriff ein',
    search_button: 'Suche',

    filters_button: 'Filter',
    apply_filters: 'Filter anwenden',

    export_button: 'Export von Daten',

    loading: 'Laden...',
    no_results: 'Nicht gefundene Datensätze',

    cancel_button: 'Annullierung',
    ok_button: 'OK',
    save_button: 'Speichern',
    delete_button: 'Entfernen',

    users: 'Benutzer',
    users_add_button: 'Benutzer hinzufügen',
    user_add: 'Hinzufügen eines Benutzers',
    user_edit: 'Bearbeiten von "{name}"',
    user_delete: 'Löschen eines Benutzers',

    user_delete_confirm_text: 'Möchten Sie den Benutzer "{name}" wirklich löschen?',

    locks: 'Locks',
    locks_add_button: 'Sperre hinzufügen',
    lock_add: 'Hinzufügen von Sperren',
    lock_edit: 'Bearbeitung von "{name}" sperren',
    lock_delete: 'Löschen von Sperren',
    lock_delete_confirm_text: 'Möchten Sie das Schloss "{name}" wirklich löschen?',

    lock_status_vacant: 'Vakant',
    lock_status_occupied: 'Besetzt',
    lock_status_own_use: 'Eigenbedarf',
    lock_status_maintenance: 'Wartung',
    lock_status_out_of_order: 'Außer Betrieb',
    lock_status_locked: 'Sperren',
    lock_status_deleted: 'Deleted',
    lock_status_disposed: 'Entsorgen',
    lock_status_reserved: 'Reservieren',

    customers: 'Units',
    customers_user_main: 'Hauptnutzer',
    customers_user_sub: 'Nebennutzer',
    customers_user_sub_for: 'Nebennutzer für',
    customer_change_manual_lock_title: 'Status der manuellen Verriegelung ändern',
    customer_change_parent_title: 'Ändern des Benutzertyps',
    customer_change_parent_error: 'Es ist nicht möglich, einen Benutzer als primären Benutzer hinzuzufügen',
    customer_change_manual_lock_message: 'Möchten Sie den manuellen Sperrstatus für diesen Kunden wirklich ändern?',
    customer_change_manual_lock_button: 'Akzeptieren',

    customer_change_bank_rating_modal_title: 'Änderung des Bankratings',
    customer_change_bank_rating_confirmation_modal_title: 'Bestätigung des Bankratings ändern',
    customer_change_bank_rating_confirmation_modal_description: 'Möchten Sie die Bankbewertung für diesen Benutzer wirklich ändern?',

    bank_rating_history_list_title: 'Die Geschichte der Änderungen im Bankrating',
    bank_rating_performed_user: 'Durchgeführter Benutzer',

    field_api: 'API ID',
    field_email: 'E-Mail',
    field_password: 'Passwort',
    field_name: 'Titelleiste',
    field_description: 'Beschreibung',
    field_status: 'Status',
    field_first_name: 'Vorname',
    field_last_name: 'Name',
    field_client_id: 'Client-ID',
    field_actions: 'Handeln',
    field_location: 'Standort',
    field_unit: 'GA / Box',
    field_user: 'Benutzer',
    field_llm_code: 'LLM-Code',
    field_satel_code: 'Satel-Code',
    field_user_has_access: 'Zugang',
    field_bank_status: 'Crefo',
    field_lock_status: 'Schlosszustand',
    field_manual_lock: 'Sperrung',

    bool_true: 'Ja',
    bool_false: 'Nein',

    pagination_next: 'Nach vorne >>',
    pagination_prev: '<< Zurück',
    show_more: 'Mehr anzeigen...',

    customer_edit_laer_access_title: 'LLM-Codes bearbeiten',
    no_data: 'Keine Daten',

    field_file_date: 'Dateidatum',
    field_file_name: 'Dateiname',
    field_file_status: 'Status',
    field_file_error: 'Fehlermeldung',
    field_file_status_completed: 'Vollendet',
    field_file_status_error: 'Fehler',
    field_file_status_in_progress: 'im Gange',
    field_file_message_copy: 'Kopieren',
    field_file_message_copied: 'Nachricht wurde kopiert',


    filter_button_show_empty: 'Leere Garagen',
    filter_button_show_locked: 'Verschlossene Garagen',

    add_user_button: 'Benutzer hinzufügen',
    add_user_modal_title: 'Benutzer hinzufügen',

    field_phone: 'Telefon',
    field_mobile: 'Mobil',
    field_city: 'Stadt',
    field_address: 'Adresse',
    field_postal_code: 'Postleitzahl',

    button_reset:'Zurücksetzen',
    last_update_date:'Letztes Aktualisierungsdatum',
    last_success_update_date:'Datum der letzten erfolgreichen Aktualisierung',

    reset_password_confirm_text: 'Möchten Sie das Benutzerkennwort wirklich zurücksetzen?',
    reset_job_confirm_text: 'Möchten Sie den Job wirklich zurücksetzen?',

    events: 'Veranstaltungen',
    field_unit_name:'Unit Name',
    field_error_type:'Einheitenname',
    field_error_message:'Nachricht',
    field_update_date:'Datum',

    alert_type_0: 'Access-Datenbankzugriffsfehler',
    alert_type_1: 'Globaler Synchronisierungsfehler',
    alert_type_2: 'Fehler beim Verbinden mit dem Satel-System zum Aktualisieren der Informationen zum Objekt {object_name}',
    alert_type_3: 'Fehler beim Blockieren des Benutzers {object_name} im Satel-System',
    alert_type_4: 'Fehler beim Ändern der Satel-API für Objekt {object_name} im Satel-System',

    alert_name: 'Name',
    alert_set_date: 'Datum festlegen',
    alert_retry_count: 'Anzahl Wiederholungsversuche'
};

export default GERMAN_MESSAGES;
