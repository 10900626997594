import ErrorType from "./Event/ErrorType";

class Event {
    id;
    errorType;
    unitName;
    errorMessage;
    createDate;
    updateDate;
    count;
    opened;

    constructor(data) {

        var formatDate = function(date){
            date = new Date(date);
            date = date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
            return (new Date(date)).toLocaleString();
        }

        if(data !== undefined && data !== null) {
            var errorType = ErrorType.fromApi(data?.errorSyncType);
            this.id = data.id;
            this.errorType = errorType.name;
            this.unitName = data?.unitName;
            this.errorMessage = data?.errorMessage;
            this.createDate = data.createDate;
            this.updateDate = data.updateDate ? formatDate(data?.updateDate) : null;
            this.count = data.count;
            this.opened = false;
        }

        else {
            this.id = '';
            this.errorType = '';
            this.unitName = '';
            this.errorMessage = '';
            this.createDate = '';
            this.updateDate = '';
            this.count = 0;
            this.opened = false;
        }
    }

   
}

export default Event;