import { messages } from "./i18n/messages";
import { LOCALES } from "./i18n/locales";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import React, { useState } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { Login } from "./pages/auth/Login";
import { List as UsersList } from "./pages/Users/List";
import { List as Events } from "./pages/Events/List"
import { List as Alerts } from "./pages/Alerts/List"
// import LocksList from "./pages/Locks/List";
import CustomersList from "./pages/Customers/List";
// import {Index} from "./pages/Index";
import { NotificationContainer } from "react-notifications";

const msalInstance = new PublicClientApplication(msalConfig);

const guestRouter = createBrowserRouter([
  { path: "/login", element: <Login /> },
  { path: "/customers", element: <CustomersList /> },
  { path: '/users', element: <UsersList /> },
  { path: '/events', element: <Events /> },
  { path: '/alerts', element: <Alerts /> },
  { path: "*", element: <Navigate to="/login" replace /> },
]);

const authenticatedRouter = createBrowserRouter([
  { path: "/", element: <CustomersList /> },
  { path: "/customers", element: <CustomersList /> },
  { path: '/users', element: <UsersList /> },
  { path: '/events', element: <Events /> },
  { path: '/alerts', element: <Alerts /> },
  // // {path: '/locks', element: <LocksList />},
  // {path: '/customers', element: <CustomersList/>},
  { path: "*", element: <Navigate to="/" /> },
]);

export const LangContext = React.createContext(null);

const getInitialLocale = () => {
  const savedLocale = localStorage.getItem("locale");
  return savedLocale || LOCALES.ENGLISH;
};

function App() {
  const [locale, setLocale] = useState(getInitialLocale());
  return (
    <IntlProvider
      messages={messages[locale]}
      locale={locale}
      defaultLocale={LOCALES.ENGLISH}
    >
      <LangContext.Provider value={{ locale: locale, setLocale: setLocale }}>
        <MsalProvider instance={msalInstance}>
          <UnauthenticatedTemplate>
            <RouterProvider router={guestRouter} />
          </UnauthenticatedTemplate>

          <AuthenticatedTemplate>
            <RouterProvider router={authenticatedRouter} />
          </AuthenticatedTemplate>
          <NotificationContainer />
        </MsalProvider>
      </LangContext.Provider>
    </IntlProvider>
  );
}

export default App;
