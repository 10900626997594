import React from 'react';
import { useEffect, useState } from "react";
import { AuthenticatedLayout } from "../../layouts/AuthenticatedLayout";
import { getEvents } from "../../backend";
import { FormattedMessage } from "react-intl";
import Search from "../../components/Search";
import { PrimaryButton } from "../../components/Buttons";
import ErrorType from '../../backend/DTO/Event/ErrorType';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

function List() {
    const theme = useTheme();

    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);

    const itemsPerPage = 100;
    const [itemOffset, setItemOffset] = useState(0);

    const [selectedErrorType, setSelectedErrorType] = useState([]);

    const [searchText, setSearchText] = useState("");

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);


    const [showMore, setShowMore] = useState(false);

    const onSearchClick = (lock_name) => {
        setItemOffset(0);
        setSearchText(lock_name);
        setLoading(true);
        getEvents(searchText, getErrorCodesForSearch(), fromDate, toDate, itemOffset, itemsPerPage)
            .then(response => {
                setShowMore(response.length >= itemsPerPage);
                setEvents(response);
            })
            .finally(() => { setLoading(false); });

    }

    const getErrorCodesForSearch = () => {
        var values = selectedErrorType.map(function (t) {
            return ErrorType.list().filter(v => { return v.name === t })[0].value;
        });

        return values.join(",");
    }

    const onLoadMoreClick = () => {
        setItemOffset(events.length + itemsPerPage);
        setLoading(true);
        getEvents(searchText, getErrorCodesForSearch(), fromDate, toDate, itemOffset, itemsPerPage)
            .then(response => {
                setShowMore(response.length >= itemsPerPage);
                setEvents([...events, ...response]);
            })
            .finally(() => { setLoading(false); });
    }

    useEffect(() => {
        onSearchClick();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getErrorTypeStyles(name, selectedTypeNames, theme) {
        return {
            fontWeight: selectedTypeNames.includes(name)
                ? theme.typography.fontWeightMedium
                : theme.typography.fontWeightRegular,
        };
    }

    const handleErrorTypesChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedErrorType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
          right: -13,
          top: 3,
          border: `2px solid ${theme.palette.background.default}`,
          padding: '0 4px',
        },
      }));

    const truncate = function(str) {
        return shouldTruncate(str) ? str.substring(0, 150) + "..." : str;
    }

    const shouldTruncate = function(str){
        return str.length > 150;
    }

    return (
        <AuthenticatedLayout>

            <FormControl sx={{ m: 1, width: '100%' }}>
                <Search onSearch={onSearchClick} onChange={(value) => { setSearchText(value) }}>

                </Search> </FormControl>
            <div>
                <FormControl sx={{ m: 1, width: 'auto', minWidth: 250 }}>
                    <InputLabel id="error-type-chip-label">Type</InputLabel>
                    <Select
                        labelId="error-type-chip-label"
                        id="error-type-chip"
                        multiple
                        className='type-selector'
                        value={selectedErrorType}
                        onChange={handleErrorTypesChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {ErrorType.list().map((errorType) => (
                            <MenuItem
                                key={errorType.value}
                                value={errorType.name}
                                style={getErrorTypeStyles(errorType.name, errorType.name, theme)}
                            >
                                {errorType.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl sx={{ m: 1, width: 'auto', minWidth: 250 }}>
                        <DatePicker
                            label="From"
                            onChange={(v) => {
                                if(v){
                                    var date = new Date(v.toString());
                                    date = date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                                    setFromDate((new Date(date)).toISOString());
                                }else{
                                    setFromDate(v?.toString());
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 'auto', minWidth: 250 }}>
                        <DatePicker
                            label="To"
                            onChange={(v) => {
                                if(v){
                                    var date = new Date(v.toString());
                                    date = date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                                    setToDate((new Date(date)).toISOString());
                                }else{
                                    setToDate(null)
                                }                                
                            }}
                        />
                    </FormControl>
                </LocalizationProvider>
            </div>
            <div className="table-box mt-8">

                <>
                    <table className="table">
                        <thead className='border-top-1 border-bottom-1'>
                            <tr>
                                <th style={{ width: '250px' }}><i><FormattedMessage id="field_unit_name" /></i></th>
                                <th style={{ width: '250px' }}><i><FormattedMessage id="field_error_type" /></i></th>
                                <th><i><FormattedMessage id="field_error_message" /></i></th>
                                <th style={{ width: '250px' }}><i><FormattedMessage id="field_update_date" /></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            {events.length > 0 ? events.map((item, index) => (
                                <>
                                <tr key={index} className={shouldTruncate(item.unitName)?'cursor-pointer':""} onClick={shouldTruncate? ()=> {
                                    item.opened = !item.opened;
                                    setEvents([...events]);
                                } : ()=>{}}>
                                    <td className="align-middle">
                                        {item?.count > 1 ?
                                    (<StyledBadge badgeContent={item?.count} color="primary">
                                       {truncate(item?.unitName)} 
                                    </StyledBadge>) 
                                    : item?.unitName} 
                                    </td>
                                    <td className="align-middle">{item?.errorType || ''}</td>
                                    <td className="align-middle">{item?.errorMessage || ''}</td>
                                    <td className="align-middle">{item?.updateDate || ''}</td>
                                </tr>
                                {
                                    shouldTruncate(item.unitName) ? 
                                    <tr key={index} className={!item.opened ? "hidden" : ""}>
                                    <td colSpan={4}>
                                        {item?.unitName}
                                    </td>
                                    </tr>
                                    :<></>
                                 }
                               
                                </>
                            )) : (
                                <tr><td colSpan={5} className="text-center"><FormattedMessage id={'no_results'} /></td></tr>
                            )}
                        </tbody>
                    </table>
                    {loading ? (
                        <div className="align-content-center text-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden"><FormattedMessage id="loading" /></span>
                            </div>
                        </div>
                    ) : (<></>)}
                    <div className='pagination-box mt-40'>
                        {showMore ? (
                            <div className="text-center mt-40">
                                <PrimaryButton
                                    onClick={() => {
                                        onLoadMoreClick();
                                    }}
                                >
                                    <FormattedMessage id={"show_more"} />
                                </PrimaryButton>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            </div>




        </AuthenticatedLayout>
    );
}

export { List };