import React from "react";
import { useMsal } from "@azure/msal-react";
import {FormattedMessage} from "react-intl";
import { Button } from "@mui/material";

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/" // redirects the top level app after logout
      });
    }
  }

  return (
    <Button onClick={() => handleLogout("popup")} color="inherit" variant="contained" className="logout-button">
      <FormattedMessage id="logout"/>
    </Button>
  );
}
