import React from 'react';
import { useEffect, useState } from "react";
import { AuthenticatedLayout } from "../../layouts/AuthenticatedLayout";
import ReactApexChart from "react-apexcharts";
import { getAlerts } from "../../backend";
import { FormattedMessage, useIntl } from "react-intl";

function List() {

  const options = {  
    chart: {
      toolbar: {
        show: false
      },
      id: 'area-datetime',
      type: 'area',
      height: 350,
      zoom: {
        autoScaleYaxis: true
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
      style: 'hollow',
    },
    xaxis: {
      type: 'datetime',
      min: new Date('01/06/2025').getTime(),
      tickAmount: 6,
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      }
    },
    stroke: {
      curve: 'smooth',
      lineCap: 'round',
      width: 0.5
    }
  }

  const intl = useIntl();

  const [series, setSeries] = useState([]);
  const [alertList, setAlertList] = useState([]);


  /*eslint no-extend-native: ["error", { "exceptions": ["Object"] }]*/
  Date.prototype.addDays = function (days) { // eslint-disable-line
    this.setDate(this.getDate() + days); // eslint-disable-line
    return this; // eslint-disable-line
  }; // eslint-disable-line

  useEffect(() => {


    getAlerts().then((result) => {

      setAlertList(result.first);

      var alers = result.second;

      
      var setDates = alers.map(a=>
      [         
          a.setDateTime.getTime(),
          1
       ] 
      );

      var resetDates = alers.map(a=>
        [   
          a.resetDateTime.getTime(),
          -1
        ] 
      );

      var dateRange = getDates(
        new Date(alers.sort((a, b) => a.setDateTime - b.setDateTime)[0].setDateTime).addDays(-1),
        new Date(alers.sort((a, b) => b.setDateTime - a.setDateTime)[0].setDateTime).addDays(1)
      );
     
      var allDate = [...setDates, ...resetDates].sort((a,b)=>{
        return a[0] - b[0];
      });

      var data = allDate.reduce(
        (acc, currentValue) => {
          var accLastVal = acc[acc.length-1][1];
          var currentVal = currentValue[1];
          var time = currentValue[0];
          var newEl = [time, accLastVal + currentVal];
          acc.push(newEl);
          return acc;
        },
        [[dateRange[0].getTime(), 0]],
      );

      const series = 
         [
          {
            name: "alerts",
            data: data
          }
        ]
      ;

      
      setSeries(series);

    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = currentDate.addDays(1);
    }
    return dateArray;
  }

  const getAlertName = (alert) => {

    var alertName = intl.formatMessage({
      id: "alert_type_" + alert.alertType,
    });

    alertName = alertName.replace("{object_name}", alert.objectToRetry);

    return alertName;
  }

  return (
    <AuthenticatedLayout>

      <ReactApexChart options={options} series={series} type="area" height={600} />

      <div className="table-box mt-8">
        <table className="table">
          <thead className='border-top-1 border-bottom-1'>
            <tr>
              <th style={{ width: '250px' }}><i><FormattedMessage id="alert_name" /></i></th>
              <th style={{ width: '350px' }}><i><FormattedMessage id="alert_set_date" /></i></th>
              <th style={{ width: '50px' }}><i><FormattedMessage id="alert_retry_count" /></i></th>
            </tr>
          </thead>
          <tbody>
            {

              alertList.map((alert, index) =>
                <tr key={index}>
                  <td>
                    {
                      getAlertName(alert)
                    }
                  </td>
                  <td>
                    {
                      alert.setDateTime?.toLocaleString()
                    }
                  </td>
                  <td>
                    {alert.retryCount ?? ""}
                  </td>
                </tr>

              )}
          </tbody>
        </table>
      </div>

    </AuthenticatedLayout>
  );
}

export { List };