class ErrorType {
    
    constructor(name, value) { this.name = name; this.value = value; }

    static fromApi(value) {
       var list = this.list()
       var result = list.filter(e=> { return e.value === value } );
       return result[0];
    }

    static list() {
        return [
            new ErrorType('Internal Error', 1),
            new ErrorType('Cant Finish Sync To LLM', 2),
            new ErrorType('Multiple Lock In System', 3),
            new ErrorType('Old Lock', 4),
            new ErrorType('User Email Empty', 5),
            new ErrorType('No Satel Api', 6),
        ];
    }
}

export default ErrorType;