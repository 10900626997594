import React from "react";
import ResponsiveAppBar from "../components/ResponsiveAppBar";

export class AuthenticatedLayout extends React.Component {
    render() {
        return (
            <>
                <ResponsiveAppBar/>
                <div className="inner-content">
                    { this.props.children }
                </div>
            </>
        );
    }
}