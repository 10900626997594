import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { useContext } from 'react';
import { LOCALES } from '../i18n/locales';
import { LangContext } from "../App";
import { SignOutButton } from "./SignOutButton";
import { FormattedMessage } from "react-intl";
import { Link, NavLink, useLocation } from 'react-router-dom'
import { Select } from '@mui/material';
import { getActiveAlerts } from "../backend";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const languages = [
  { name: 'English', code: LOCALES.ENGLISH },
  { name: 'Русский', code: LOCALES.RUSSIAN },
  { name: 'Deutsch', code: LOCALES.GERMAN }
];

function DrawerAppBar(props) {

  const { locale, setLocale } = useContext(LangContext);
  const [activeAlerts, setActiveAlerts] = useState(0);

  const location = useLocation();
  const handleChangeLocale = ({ target: { value } }) => {
    setLocale(value);
    localStorage.setItem('locale', value);
  };


  useEffect(() => {
    getActiveAlerts().then((r) => {
      console.log(JSON.stringify(r.length));
      setActiveAlerts(r.length);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -8,
      top: 2,
      border: `2px solid ${theme.palette.background.default}`,
      padding: '0 4px',
    },
  }));


  return (
    <Box sx={{ display: 'flex' }} className="navbar">
      <CssBaseline />
      <AppBar component="nav" className="bg-primary">
        <Toolbar>
          <NavLink className="navbar-brand" to="/"><img width={100} src="/logo.png" alt="" /></NavLink>
          <Box sx={{ flexGrow: 1, display: 'flex' }} className="ml-50">
            <Link className={"nav-link " + ((location.pathname === "/customers" || location.pathname === "" || location.pathname === "/") ? "active" : undefined)} aria-current="page" to="/customers">
              <FormattedMessage id="customers" />
            </Link>
            <Link className={"nav-link " + (location.pathname === "/users" ? "active" : "")} aria-current="page" to="/users">
              <FormattedMessage id="users" />
            </Link>
            <Link className={"nav-link " + (location.pathname === "/events" ? "active" : "")} aria-current="page" to="/events">
              Events
            </Link>


          </Box>

          <Box sx={{ display: 'block' }}>

          <Link className={"nav-link " + (location.pathname === "/alerts" ? "active" : "")} aria-current="page" to="/alerts">
                  <StyledBadge badgeContent={activeAlerts} color="primary">
                  {
                    <NotificationsActiveIcon/>
                  }
                </StyledBadge>
            </Link>
            </Box>

          <Box sx={{ display: 'block' }}>

            <Select value={locale} onChange={handleChangeLocale} style={{ height: 30 }} className="form-control">
              {languages.map(({ name, code }) => (
                <option key={code} value={code}>{name}</option>
              ))}
            </Select>
          </Box>
          <Box sx={{ display: 'block' }} className="ml-10">
            <SignOutButton />
          </Box>

        </Toolbar>
      </AppBar>

    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
