class Alert {
    id;
    alertType;
    alertDescription;
    objectToRetry;
    setDateTime;
    resetDateTime;
    retryCount;
    updateDate;

    constructor(data) {
        if (data !== undefined && data !== null) {
            this.alertType = data?.alertType;
            this.alertDescription = data.alertDescription;
            this.objectToRetry = data.objectToRetry;
            this.setDateTime = data.setDateTime ? new Date(data?.setDateTime) : null;
            this.resetDateTime = data.resetDateTime ? new Date(data?.resetDateTime) : null;
            this.retryCount = data.retryCount;
            this.updateDate = data.updateDate ? new Date(data?.updateDate) : null;
        }

        else {
            this.id = "";
            this.alertType = "";
            this.alertDescription = "";
            this.objectToRetry = "";
            this.setDateTime = "";
            this.resetDateTime = "";
            this.retryCount = 0;
            this.updateDate = '';
        }
    }
}

export default Alert;